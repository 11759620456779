import {
  computed,
  reactive,
  toRefs
} from 'vue'

import { defineStore } from 'pinia'

import * as DictAPI from '@/api/modules/utils'
import { IDict } from '@/models/index'

/**
 * @description 缓存字典
 * @author
 */
export const useDictStore = defineStore('dictStore', () => {
  const state = reactive<{
    map: Map<string, Array<IDict.Model>>
		queue: Map<string, boolean>;
  }>({
    map: new Map(),
    queue: new Map()
  })

  const getDict = async (params: { type: string | null }, exclude?: string[]): Promise<Array<IDict.Model>> => {
    return new Promise((resolve) => {
      if (!params.type) return []
      if (state.queue.has(params.type)) {
        const interval: NodeJS.Timeout = setInterval(() => {
          const dictList = state.map.get(params.type!)
          if (dictList) {
            clearInterval(interval)
            resolve(exclude ? dictList!.filter(item => !exclude.includes(item.value)) : dictList!)
          }
        }, 500)
      } else {
        // 加入队列
        state.queue.set(params.type, true)
        const t = new Date().getTime()
        DictAPI.getDictList({ type: params.type, t: t + Math.floor(Math.random() * 100 + 1) }).then(({ data }) => {
          state.map.set(params.type!, data!)
          resolve(exclude ? data!.filter(item => !exclude.includes(item.value)) : data!)
        })
      }
    })
  }

  const getDictItem = computed(() => {
    return (dictType: string | null, value: string | string[] | null) => {
      if (!dictType || !value) return
      const dictList = state.map.get(dictType)
      if (dictList && value) {
        if (typeof value === 'string') {
          return dictList.filter(item => value.split(',').includes(item.value))
        } else {
          return dictList.filter(item => value.includes(item.value))
        }
      } else {
        return dictList
      }
    }
  })

  return {
    ...toRefs(state),
    getDictItem,
    getDict
  }
})

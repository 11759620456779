import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/system/detection',
    component: Layout,
    redirect: '/system/detection/institution/index',
    meta: {
      title: '检测机构管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'institution/index',
        name: 'SystemDetectionInstitutionIndex',
        component: () => import('@/views/system/detection/institution/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '检测机构管理'
        }
      },
      {
        path: 'project/index',
        name: 'SystemDetectionProjectIndex',
        component: () => import('@/views/system/detection/project/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '检验项目管理'
        }
      },
      {
        path: 'inspect/index',
        name: 'SystemDetectionInspectIndex',
        component: () => import('@/views/system/detection/inspect/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '检查项目管理'
        }
      },
      {
        path: 'settlementPrice/index',
        name: 'SystemDetectionSettlementPriceIndex',
        component: () => import('@/views/system/detection/settlementPrice/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '结算价管理'
        }
      }
    ]
  }
]

export default router

import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business',
    component: Layout,
    redirect: '/business/patient/index',
    meta: {
      title: '患者管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'patient/index',
        name: 'BusinessPatientIndex',
        component: () => import('@/views/business/patient/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '患者管理'
        }
      },
      {
        path: 'clientuser/index',
        name: 'BusinessClientuserIndex',
        component: () => import('@/views/business/clientuser/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '用户成员管理'
        }
      },
      {
        path: 'clientuser/family',
        name: 'BusinessClientuserFamily',
        component: () => import('@/views/business/clientuser/family.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '就诊人管理'
        }
      }
    ]
  }
]

export default router

import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/invoice',
    component: Layout,
    redirect: '/business/invoice/invoiceConfig/index',
    meta: {
      title: '开票管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'invoiceConfig/index',
        name: 'BusinessInvoiceInvoiceConfigIndex',
        component: () => import('@/views/business/invoice/invoiceConfig/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '开票信息设置'
        }
      },
      {
        path: 'invoiceList/index',
        name: 'BusinessInvoiceInvoiceListIndex',
        component: () => import('@/views/business/invoice/invoiceList/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '开票列表'
        }
      }
    ]
  }
]

export default router

import { PORT1 } from '@/api/config/servicePort'
import http from '@/api'
import { IDict, IRegion, ISpecialist, IExpress } from '@/models'
import { ResPage } from '../interface/index'

// * 获取字典列表 list
export const getDictList = (params: { type: string, t?:number }) => {
  return http.get<IDict.Model[]>(PORT1 + `/admin/dict/type/${params.type}`, params)
}

// * 权限区域树-表单
export const getRegionList = (params?: { deptId?: string, roleId?: string }) => {
  return http.get<IRegion.Model[]>(PORT1 + `/admin/region/tree`, params)
}

/**  专家分页 */
export const getSpecialistPage = () => {
  return http.get<ResPage<ISpecialist.Model>>(PORT1 + `/admin/specialist/page`)
}

/**  快递列表 */
export const getExpressList = () => {
  return http.get<IExpress.Model[]>(PORT1 + `/admin/sys-express-company/list`)
}

import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/report',
    component: Layout,
    redirect: '/business/report/index',
    meta: {
      title: '报告管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'index',
        name: 'BusinessReportIndex',
        component: () => import('@/views/business/report/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '报告管理'
        }
      },
      {
        path: 'interpret',
        name: 'BusinessReportInterpret',
        component: () => import('@/views/business/report/interpret.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '专家解读需求管理'
        }
      }
    ]
  }
]

export default router

import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/system/banee',
    component: Layout,
    redirect: '/system/banee/base/index',
    meta: {
      title: '伴医店铺管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'base/index',
        name: 'SystemBaneeBaseIndex',
        component: () => import('@/views/system/banee/base/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '基础配置'
        }
      },
      {
        path: 'shop/index',
        name: 'SystemBaneeShopIndex',
        component: () => import('@/views/system/banee/shop/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '店铺列表'
        }
      },
      {
        path: 'user/index',
        name: 'SystemBaneeUserIndex',
        component: () => import('@/views/system/banee/user/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '伴医用户管理'
        }
      },
      {
        path: 'cash/index',
        name: 'SystemBaneeCashIndex',
        component: () => import('@/views/system/banee/cash/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '账户管理'
        }
      },
      {
        path: 'deposit/refund/index',
        name: 'SystemBaneeDepositRefundIndex',
        component: () => import('@/views/system/banee/deposit/refund/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '退保管理'
        }
      },
      {
        path: 'content/index',
        name: 'SystemBaneeContentIndex',
        component: () => import('@/views/system/banee/content/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '内容中心管理'
        }
      },
      {
        path: 'bank/index',
        name: 'SystemBaneeBankIndex',
        component: () => import('@/views/system/banee/bank/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '银行卡管理'
        }
      },
      {
        path: 'order/materialOrder/index',
        name: 'SystemBaneeOrderMaterialOrder',
        component: () => import('@/views/system/banee/order/materialOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '伴医推广管理'
        }
      }
    ]
  }
]

export default router

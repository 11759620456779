import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/finance/inspectStatistics',
    component: Layout,
    redirect: '/finance/inspectStatistics/index',
    meta: {
      title: '检验服务业务统计',
      keepAlive: true,
      requiresAuth: true,
    },
    children: [
      // {
      //   path: 'index',
      //   name: 'FinanceInspectStatisticsIndex',
      //   component: () => import('@/views/finance/inspectStatistics/index.vue'),
      //   meta: {
      //     keepAlive: true,
      //     requiresAuth: true,
      //     title: '检验服务业务统计',
      //     linkFlag: true
      //   }
      // }
    ]
  },
  {
    path: '/finance/statistics',
    component: Layout,
    redirect: '/finance/statistics/index',
    meta: {
      title: '检测机构送检统计',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'index',
        name: 'FinanceStatisticsIndex',
        component: () => import('@/views/finance/statistics/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '检测机构送检统计'
        }
      }
    ]
  }
]

export default router

import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/order',
    component: Layout,
    redirect: '/business/order/homeCareOrder/index',
    meta: {
      title: '订单管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'homeCareOrder/index',
        name: 'BusinessOrderHomeCareOrderIndex',
        component: () => import('@/views/business/order/homeCareOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '医护订单管理'
        }
      },
      {
        path: 'examineOrder/index',
        name: 'BusinessOrderExamineOrderIndex',
        component: () => import('@/views/business/order/examineOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '检验订单管理'
        }
      },
      {
        path: 'inspectOrder/index',
        name: 'BusinessOrderInspectOrderIndex',
        component: () => import('@/views/business/order/inspectOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '检查订单管理'
        }
      },
      {
        path: 'couponOrder/index',
        name: 'BusinessOrderCouponOrderIndex',
        component: () => import('@/views/business/order/couponOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '卡券订单管理'
        }
      },
      {
        path: 'materialOrder/index',
        name: 'BusinessOrderMaterialOrderIndex',
        component: () => import('@/views/business/order/materialOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '实物订单管理'
        }
      },
      {
        path: 'rechargeOrder/index',
        name: 'BusinessOrderRechargeOrderIndex',
        component: () => import('@/views/business/order/rechargeOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '充值订单管理'
        }
      },
      {
        path: 'generalOrder/index',
        name: 'BusinessOrderGeneralOrderIndex',
        component: () => import('@/views/business/order/generalOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '总订单管理'
        }
      },
      {
        path: 'renewOrder/index',
        name: 'BusinessOrderRenewOrderIndex',
        component: () => import('@/views/business/order/renewOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '续费订单管理'
        }
      },
      {
        path: 'serveOrder/index',
        name: 'BusinessOrderServeOrderIndex',
        component: () => import('@/views/business/order/serveOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '服务订单管理'
        }
      },
      {
        path: 'registerOrder/index',
        name: 'BusinessOrderRegisterOrderIndex',
        component: () => import('@/views/business/order/registerOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '挂号订单管理'
        }
      },
      {
        path: 'payOrder/index',
        name: 'BusinessOrderPayOrderIndex',
        component: () => import('@/views/business/order/payOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '缴费订单管理'
        }
      },
      {
        path: 'depositOrder/index',
        name: 'BusinessDepositOrderOrderIndex',
        component: () => import('@/views/business/order/depositOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '保证金订单管理'
        }
      }
    ]
  }
]

export default router

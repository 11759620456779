import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/admin',
    component: Layout,
    redirect: '/admin/user/index',
    meta: {
      title: '权限管理',
      keepAlive: true,
      requiresAuth: true,
    },
    children: [
      {
        path: 'user/index',
        name: 'AdminUserIndex',
        component: () => import('@/views/admin/user/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '用户管理',
        }
      },
      {
        path: 'menu/index',
        name: 'AdminMenuIndex',
        component: () => import('@/views/admin/menu/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '菜单管理',
        }
      },
      {
        path: 'role/index',
        name: 'AdminRoleIndex',
        component: () => import('@/views/admin/role/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '角色管理',
        }
      },
      {
        path: 'dept/index',
        name: 'AdminDeptIndex',
        component: () => import('@/views/admin/dept/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '部门管理',
        }
      },
      {
        path: 'post/index',
        name: 'AdminPostIndex',
        component: () => import('@/views/admin/post/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '岗位管理',
        }
      },
      {
        path: 'file/index',
        name: 'AdminFileIndex',
        component: () => import('@/views/admin/file/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '文件管理',
        }
      },
      {
        path: 'client/index',
        name: 'AdminClientIndex',
        component: () => import('@/views/admin/client/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '终端管理',
        }
      },
      {
        path: 'social/index',
        name: 'AdminSocialIndex',
        component: () => import('@/views/admin/social/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '密钥管理',
        }
      },
      {
        path: 'token/index',
        name: 'AdminTokenIndex',
        component: () => import('@/views/admin/token/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '令牌管理',
        }
      },
      {
        path: 'route/index',
        name: 'AdminRouteIndex',
        component: () => import('@/views/admin/route/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '动态路由',
        }
      }
    ]
  }
]

export default router

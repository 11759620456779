import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/system/pay',
    component: Layout,
    redirect: '/system/pay/index',
    meta: {
      title: '支付管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'merchant/index',
        name: 'SystemPayMerchantIndex',
        component: () => import('@/views/system/pay/merchant/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '商户管理'
        }
      },
      {
        path: 'configure/index',
        name: 'SystemPayConfigureIndex',
        component: () => import('@/views/system/pay/configure/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '订单收款账户设置'
        }
      }
    ]
  }
]

export default router

<template>
  <div>
    <template v-for="(item, index) in dictItemList" :key="index">
      <el-tag v-if="type === 'tag'" :color="item.color" effect="dark">{{ item.label }}</el-tag>
      <span v-if="type === 'text'" :style="{color: item.color}">{{ item.label }}</span>
    </template>
    <span v-if="!dictItemList || dictItemList.length === 0">--</span>
  </div>

</template>

<script lang="ts" setup name="UseDictTag">
import { useDictStore } from '@/store/modules/dict'
import { computed, onMounted, watch } from 'vue'

type menuType = 'tag' | 'text'

interface IProps {
	value?: string | string[]
	dictType: string
	type?: menuType
}

const props = withDefaults(defineProps<IProps>(), {
  value: '',
  type: 'tag'
})

const { getDictItem, getDict } = useDictStore()

const dictItemList = computed(() => {
  if (props.value) {
    return getDictItem(props.dictType, props.value)
  } else {
    return []
  }
})

watch(() => props.dictType, (val) => {
  getDict({ type: val })
}, { deep: true })

onMounted(() => {
  getDict({ type: props.dictType })
})

</script>

<style lang="scss" scoped></style>

import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/referral',
    component: Layout,
    redirect: '/business/referral/index',
    meta: {
      title: '转诊管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'index',
        name: 'BusinessReferralIndex',
        component: () => import('@/views/business/referral/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '转诊管理'
        }
      }
    ]
  }
]

export default router

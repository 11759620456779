import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw
} from 'vue-router'

// * 导入所有router

// 基础模块
const adminRouters = import.meta.globEager('./modules/admin/*.ts')
const dashboardRouters = import.meta.globEager('./modules/dashboard/*.ts')
const systemRouters = import.meta.globEager('./modules/system/*.ts')
const businessRouters = import.meta.globEager('./modules/business/*.ts')
const financeRouters = import.meta.globEager('./modules/finance/*.ts')

const metaRouters = Object.assign(
  adminRouters,
  dashboardRouters,
  systemRouters,
  businessRouters,
  financeRouters
)
// * 处理路由
export const routerArray: RouteRecordRaw[] = []
Object.keys(metaRouters).forEach(item => {
  Object.keys(metaRouters[item]).forEach((key: any) => {
    routerArray.push(...metaRouters[item][key])
  })
})

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'login' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      requiresAuth: false,
      title: '登录页',
      key: 'login'
    }
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/components/ErrorMessage/403.vue'),
    meta: {
      requiresAuth: true,
      title: '403页面',
      key: '403'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/components/ErrorMessage/404.vue'),
    meta: {
      requiresAuth: false,
      title: '404页面',
      key: '404'
    }
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/components/ErrorMessage/500.vue'),
    meta: {
      requiresAuth: false,
      title: '500页面',
      key: '500'
    }
  },
  {
    path: '/report/pdf',
    name: 'reportPdf',
    component: () => import('@/views/business/report/pdf.vue'),
    meta: {
      requiresAuth: false,
      title: '报告详情',
      key: 'pdf'
    }
  },
  {
    path: '/financeInspectStatisticsIndex',
    name: 'FinanceInspectStatisticsIndex',
    component: () => import('@/views/finance/inspectStatistics/index.vue'),
    meta: {
      requiresAuth: true,
      title: '检验服务业务统计',
      isLink: true
    }
  },
  ...routerArray,
  {
    // 找不到路由重定向到404页面
    path: '/:pathMatch(.*)',
    redirect: { name: '404' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  strict: false,
  // 切换页面，滚动到最顶部
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export default router

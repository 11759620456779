import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/carePlatform',
    component: Layout,
    redirect: '/business/carePlatform/index',
    meta: {
      title: '医护产品管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'index',
        name: 'BusinessCarePlatformIndex',
        component: () => import('@/views/business/carePlatform/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '医护产品管理'
        }
      }
    ]
  }
]

export default router

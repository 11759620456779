import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/agency',
    component: Layout,
    redirect: '/business/agency/basics/index',
    meta: {
      title: '医护商品管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'basics/index',
        name: 'BusinessAgencyBasicsIndex',
        component: () => import('@/views/business/agency/basics/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '基础信息管理'
        }
      },
      {
        path: 'clinic/index',
        name: 'BusinessAgencyClinicIndex',
        component: () => import('@/views/business/agency/clinic/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '开通商品代理机构关联'
        }
      },
      {
        path: 'marketing/index',
        name: 'BusinessAgencyMarketingIndex',
        component: () => import('@/views/business/agency/marketing/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '检验商品打包管理'
        }
      },
      {
        path: 'agent/index',
        name: 'BusinessAgencyAgentIndex',
        component: () => import('@/views/business/agency/agent/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '推广订单管理'
        }
      },
      {
        path: 'settlement/index',
        name: 'BusinessAgencySettlementIndex',
        component: () => import('@/views/business/agency/settlement/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '结算订单管理'
        }
      }
    ]
  }
]

export default router

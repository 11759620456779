import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/sample',
    component: Layout,
    redirect: '/business/sample/index',
    meta: {
      title: '样本管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'index',
        name: 'BusinessSampleIndex',
        component: () => import('@/views/business/sample/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '样本管理'
        }
      },
      {
        path: 'monitoring',
        name: 'BusinessSampleMonitoring',
        component: () => import('@/views/business/sample/monitoring.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '样本状态监控'
        }
      }
    ]
  }
]

export default router

import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/afterSales',
    component: Layout,
    redirect: '/business/afterSales/sales/index',
    meta: {
      title: '售后管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'sales/index',
        name: 'BusinessAfterSalesIndex',
        component: () => import('@/views/business/afterSales/sales/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '自主售后管理'
        }
      },
      {
        path: 'cancelOrder/index',
        name: 'BusinessCancelOrderIndex',
        component: () => import('@/views/business/afterSales/cancelOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '取消订单管理'
        }
      }
    ]
  }
]

export default router

import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/system/clinic',
    component: Layout,
    redirect: '/system/clinic/clinic/index',
    meta: {
      title: '诊所管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'clinic/index',
        name: 'SystemClinicClinicIndex',
        component: () => import('@/views/system/clinic/clinic/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '诊所管理'
        }
      },
      {
        path: 'clinicUser/index',
        name: 'SystemClinicClinicUserIndex',
        component: () => import('@/views/system/clinic/clinicUser/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '诊所成员管理'
        }
      },
      {
        path: 'careConfig/index',
        name: 'SystemClinicCareConfigIndex',
        component: () => import('@/views/system/clinic/careConfig/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '医护到家管理'
        }
      },
      {
        path: 'runningFees/index',
        name: 'SystemClinicRunningFeesIndex',
        component: () => import('@/views/system/clinic/runningFees/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '跑腿费管理'
        }
      },
      {
        path: 'discount/index',
        name: 'SystemClinicDiscountIndex',
        component: () => import('@/views/system/clinic/discount/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '定价折扣管理'
        }
      },
      {
        path: 'overtime/index',
        name: 'SystemClinicOvertimeIndex',
        component: () => import('@/views/system/clinic/overtime/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '诊所效期管理'
        }
      },
      {
        path: 'department/index',
        name: 'SystemClinicDepartmentIndex',
        component: () => import('@/views/system/clinic/department/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '科室管理'
        }
      },
      {
        path: 'tag/index',
        name: 'SystemClinicTagIndex',
        component: () => import('@/views/system/clinic/tag/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '标签管理'
        }
      },
    ]
  }
]

export default router

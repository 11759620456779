import { RouteRecordRaw } from 'vue-router'

import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/system/basics',
    component: Layout,
    redirect: '/system/basics/content/file/index',
    meta: {
      title: '基础数据',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'content/file/index',
        name: 'SystemBasicsContentFileIndex',
        component: () => import('@/views/system/basics/content/file/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '图片素材管理'
        }
      },
      {
        path: 'content/region/index',
        name: 'SystemBasicsContentRegionIndex',
        component: () => import('@/views/system/basics/content/region/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '行政区域管理'
        }
      },
      {
        path: 'content/advert/location/index',
        name: 'SystemBasicsContentAdvertLocationIndex',
        component: () => import('@/views/system/basics/content/advert/location/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '广告位管理'
        }
      },
      {
        path: 'content/advert/index',
        name: 'SystemBasicsContentAdvertIndex',
        component: () => import('@/views/system/basics/content/advert/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '广告管理'
        }
      },
      {
        path: 'content/article/category/index',
        name: 'SystemBasicsContentArticleCategoryIndex',
        component: () => import('@/views/system/basics/content/article/category/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '文章分类管理'
        }
      },
      {
        path: 'content/article/index',
        name: 'SystemBasicsContentArticleIndex',
        component: () => import('@/views/system/basics/content/article/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '文章管理'
        }
      },
      {
        path: 'goods/type/index',
        name: 'SystemBasicsGoodsTypeIndex',
        component: () => import('@/views/system/basics/goods/type/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '商品类型管理'
        }
      },
      {
        path: 'goods/market/index',
        name: 'SystemBasicsGoodsMarketIndex',
        component: () => import('@/views/system/basics/goods/market/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '商品运营管理'
        }
      },
      {
        path: 'goods/goods/index',
        name: 'SystemBasicsGoodsGoodsIndex',
        component: () => import('@/views/system/basics/goods/goods/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '商品管理'
        }
      },
      {
        path: 'goods/delivery/index',
        name: 'SystemBasicsGoodsDeliveryIndex',
        component: () => import('@/views/system/basics/goods/delivery/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '配送规则管理'
        }
      },
      {
        path: 'inspection/project/index',
        name: 'SystemBasicsInspectionProjectIndex',
        component: () => import('@/views/system/basics/inspection/project/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '检验项目'
        }
      },
      {
        path: 'bank/index',
        name: 'SystemBasicsBankIndex',
        component: () => import('@/views/system/basics/bank/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '银行管理'
        }
      },
      {
        path: 'expressage/index',
        name: 'SystemBasicsExpressageIndex',
        component: () => import('@/views/system/basics/expressage/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '快递公司'
        }
      },
      {
        path: 'sensitive/index',
        name: 'SystemBasicsSensitiveIndex',
        component: () => import('@/views/system/basics/sensitive/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '敏感词管理'
        }
      },
      {
        path: 'keywords/index',
        name: 'SystemBasicsKeywordsIndex',
        component: () => import('@/views/system/basics/keywords/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '热搜词管理'
        }
      },
      {
        path: 'address/index',
        name: 'SystemBasicsAddressIndex',
        component: () => import('@/views/system/basics/address/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '地址管理'
        }
      }
    ]
  }
]

export default router

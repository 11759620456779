import { isArray } from '@/utils/is'
import http from '@/api'
import { ElMessage } from 'element-plus/es/components/index'

/**
 * @description 获取sessionStorage
 * @param key Storage名称
 * @returns {String} Storage值
 */
export function localGet(key: string): string {
  const value = window.sessionStorage.getItem(key)
  try {
    return JSON.parse(window.sessionStorage.getItem(key) as string)
  } catch (error) {
    return value
  }
}

/**
 * 动态插入css
 */

export const loadStyle = (url: string) => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}

/**
 * @description 存储sessionStorage
 * @param key Storage名称
 * @param value Storage值
 */
export function localSet(key: string, value: any) {
  window.sessionStorage.setItem(key, JSON.stringify(value))
}

/**
 * @description 清除sessionStorage
 * @param key Storage名称
 */
export function localRemove(key: string) {
  window.sessionStorage.removeItem(key)
}

/**
 * 清除所有sessionStorage
 * @returns {String}
 */
export function localClear(): string {
  window.sessionStorage.clear()
}

/**
 * @description 对象数组深克隆
 * @param obj 源对象
 * @returns {obj} 克隆后的对象
 */
export function deepCopy<T>(obj: any): T {
  let newObj: any
  try {
    newObj = obj.push ? [] : {}
  } catch (error) {
    newObj = {}
  }
  for (const attr in obj) {
    if (typeof obj[attr] === 'object') {
      newObj[attr] = deepCopy(obj[attr])
    } else {
      newObj[attr] = obj[attr]
    }
  }
  return newObj
}

/**
 * @description 判断数据类型
 * @param val 需要判断类型的数据
 * @returns {string} 数据类型
 */
export function isType(val: any): string {
  if (val === null) return 'null'
  if (typeof val !== 'object') return typeof val
  else return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase()
}

/**
 * @description 生成随机数
 * @param min 最小值
 * @param max 最大值
 * @returns {number}
 */
export function randomNum(min: number, max: number): number {
  const num = Math.floor(Math.random() * (min - max) + max)
  return num
}

/**
 * @description 获取浏览器默认语言
 * @returns {String} 语言
 */
export function getBrowserLang(): string {
  const browserLang = navigator.language ? navigator.language : navigator.browserLanguage
  let defaultBrowserLang = ''
  if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
    defaultBrowserLang = 'zh'
  } else {
    defaultBrowserLang = 'en'
  }
  return defaultBrowserLang
}

/**
 * @description 递归查询当前路由所对应的tabPane（暂时没用了）
 * @param menuList 菜单列表
 * @param path 当前地址
 * @returns {Array} 当前路由所对应的tabPane
 */
export function getTabPane<T, U>(menuList: any[], path: U): T {
  let result: any
  for (const item of menuList || []) {
    if (item.path === path) result = item
    const res = getTabPane(item.children, path)
    if (res) result = res
  }
  return result
}

/**
 * 使用递归处理路由菜单
 * @param newArr 所有菜单数组
 */
export function handleRouter(routerList: Menu.MenuOptions[], newArr: string[] = []) {
  routerList.forEach((item: Menu.MenuOptions) => {
    typeof item === 'object' && item.path && newArr.push(item.path)
    item.children && item.children.length && handleRouter(item.children, newArr)
  })
  return newArr
}

/**
 * @description 格式化表格单元格默认值
 * @param row 行
 * @param col 列
 * @param callValue 当前单元格值
 * @return {String} 格式化后的值
 * */
export function defaultFormat(row: number, col: number, callValue: any): string {
  // 如果当前值为数组,使用 / 拼接（根据需求自定义）
  if (isArray(callValue)) return callValue.length ? callValue.join(' / ') : '--'
  return callValue ?? '--'
}

/**
 * @description 根据枚举列表查询当需要的数据
 * @param callValue 当前单元格值
 * @param enumData 枚举列表
 * @param type 过滤类型（目前只有 tag）
 * @return {String} 格式化后的值
 * */
export function filterEnum(callValue: any, enumData: any[] = [], type?: string): string {
  const filterData = enumData.find(item => item.value === callValue)
  if (type === 'tag') return filterData?.tagType ? filterData.tagType : ''
  return filterData ? filterData.label : '--'
}

/* src/utils.index.js */

export const mix = (color1 = '', color2 = '', weight = 0) => {
  weight = Math.max(Math.min(Number(weight), 1), 0)
  const r1 = parseInt(color1.substring(1, 3), 16)
  const g1 = parseInt(color1.substring(3, 5), 16)
  const b1 = parseInt(color1.substring(5, 7), 16)
  const r2 = parseInt(color2.substring(1, 3), 16)
  const g2 = parseInt(color2.substring(3, 5), 16)
  const b2 = parseInt(color2.substring(5, 7), 16)
  const r = Math.round(r1 * (1 - weight) + r2 * weight)
  const g = Math.round(g1 * (1 - weight) + g2 * weight)
  const b = Math.round(b1 * (1 - weight) + b2 * weight)
  const R = ('0' + (r || 0).toString(16)).slice(-2)
  const G = ('0' + (g || 0).toString(16)).slice(-2)
  const B = ('0' + (b || 0).toString(16)).slice(-2)
  return '#' + R + G + B
}

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time: any, cFormat: string): string | null {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj:{
		[propName: string]: any
	} = {
	  y: date.getFullYear(),
	  m: date.getMonth() + 1,
	  d: date.getDate(),
	  h: date.getHours(),
	  i: date.getMinutes(),
	  s: date.getSeconds(),
	  a: date.getDay()
	}
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * 获取星期数字
 * @param {number} week
 * @returns {string}
 */
export function formatWeek(week: number): string {
  return ['', '一', '二', '三', '四', '五', '六', '天'][week]
}

export type dateTypeModel = 'add' | 'minus'
/**
 * 获取一定时间的时间戳
 * @param {number} time
 * @param {number} day
 * @param {dateTypeModel} type
 * @returns {Date}
 */
export function variationTime(time: number, day:number, type: dateTypeModel): Date {
  if (type === 'add') {
    return new Date(time + 3600 * 1000 * 24 * day)
  } else {
    return new Date(time - 3600 * 1000 * 24 * day)
  }
}

export type weekTime = {
	start: Date
	end: Date
}
/**
 * 获取一周每天的日期
 * @param {Date} start
 * @param {Date} end
 * @returns {any[]}
 */
export function getWeekList(start: Date, end: Date): any[] {
  return [
    parseTime(start, '{y}-{m}-{d}'),
    parseTime(new Date(start.getTime() + 3600 * 1000 * 24 * 1), '{y}-{m}-{d}'),
    parseTime(new Date(start.getTime() + 3600 * 1000 * 24 * 2), '{y}-{m}-{d}'),
    parseTime(new Date(start.getTime() + 3600 * 1000 * 24 * 3), '{y}-{m}-{d}'),
    parseTime(new Date(start.getTime() + 3600 * 1000 * 24 * 4), '{y}-{m}-{d}'),
    parseTime(new Date(start.getTime() + 3600 * 1000 * 24 * 5), '{y}-{m}-{d}'),
    parseTime(end, '{y}-{m}-{d}'),
  ]
}
/**
 * 获取选中时间的一周
 * @param {Date} timeNum
 * @returns {weekTime}
 */
export function weekType(timeNum: Date): weekTime {
  let startDate = new Date()
  let endDate = new Date()
  switch (timeNum.getDay()) {
    case 0:
      startDate = variationTime(timeNum.getTime(), 6, 'minus')
      endDate = timeNum
      break
    case 1:
      startDate = timeNum
      endDate = variationTime(timeNum.getTime(), 6, 'add')
      break
    case 2:
      startDate = variationTime(timeNum.getTime(), 1, 'minus')
      endDate = variationTime(timeNum.getTime(), 5, 'add')
      break
    case 3:
      startDate = variationTime(timeNum.getTime(), 2, 'minus')
      endDate = variationTime(timeNum.getTime(), 4, 'add')
      break
    case 4:
      startDate = variationTime(timeNum.getTime(), 3, 'minus')
      endDate = variationTime(timeNum.getTime(), 3, 'add')
      break
    case 5:
      startDate = variationTime(timeNum.getTime(), 4, 'minus')
      endDate = variationTime(timeNum.getTime(), 2, 'add')
      break
    case 6:
      startDate = variationTime(timeNum.getTime(), 5, 'minus')
      endDate = variationTime(timeNum.getTime(), 1, 'add')
      break
  }
  return { start: startDate, end: endDate }
}

/**
 * 获取一周的起始
 */
export function getMonday(type: string, dates: number) {
  const now = new Date()
  const nowTime = now.getTime()
  const day = now.getDay()
  const longTime = 24 * 60 * 60 * 1000
  const n = longTime * 7 * (dates || 0)
  let dd = null
  let ddNum = 0
  if (type === 's') {
    ddNum = nowTime - (day - 1) * longTime + n
  }
  if (type === 'e') {
    ddNum = nowTime + (7 - day) * longTime + n
  }
  dd = new Date(ddNum)
  const y = dd.getFullYear().toString()
  let m = (dd.getMonth() + 1).toString()
  let d = dd.getDate().toString()
  m = parseInt(m) < 10 ? '0' + m : m
  d = parseInt(d) < 10 ? '0' + d : d
  const dayStr = y + '-' + m + '-' + d
  return dayStr
}

/**
 * 通过身份证号获取出生日期，年龄，性别
 */
export function getIdNoObj(idNo :string, type :number) {
  // "证件号长度不合法";
  if (idNo.length !== 18) return
  // 截取 6 到10 位 获取到出生年龄
  const str1 = parseInt(idNo.substring(6, 10))
  const str2 = parseInt(idNo.substring(10, 12))
  const str3 = parseInt(idNo.substring(12, 14))
  // 获取性别
  if (type === 1) {
    // 获取出生年月
    const num = parseInt(idNo.charAt(16))
    if (num % 2 === 0) {
      return `2`
    } else {
      return `1`
    }
  } else if (type === 2) {
    return `${str1}-${str2 < 10 ? '0' + str2 : str2}-${str3 < 10 ? '0' + str3 : str3}`
    // 获取年龄
  } else if (type === 3) {
    // 获取当前年份
    const myDate = new Date()
    const month = myDate.getMonth() + 1
    const day = myDate.getDate()
    let age = myDate.getFullYear() - str1 - 1
    // 判断生日过了没有
    if (str2 < month || str2 === month && str3 <= day) {
      age++
    }
    return age
  }
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time:number|string, option?:string): string {
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const now = Date.now()

  const diff = (now - date.getTime()) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      date.getMonth() +
      1 +
      '月' +
      date.getDate() +
      '日' +
      date.getHours() +
      '时' +
      date.getMinutes() +
      '分'
    )
  }
}

/**
 * 判断是否为空
 */
export function validatenull(val:any) {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val === null || val === 'undefined' || val === undefined || val === '') return true
    return false
  }
  return false
}

/**
 *
 * @param url 目标下载接口
 * @param query 查询参数
 * @param fileName 文件名称
 * @returns {*}
 */
export function downBlobFile(url: string, query: any, fileName: string) {
  http.get<any>(url, query, { responseType: 'blob' }).then(response => {
    console.log(response)
    // 处理返回的文件流
    const blob = response
    console.log(blob)
    if (blob && blob.size === 0) {
			 ElMessage({
        type: 'error',
        message: `内容为空，无法下载`
      })
      return
    }
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      window.URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}

<template>
  <el-config-provider :button="config" :locale="i18nLocale" :size="assemblySize">
    <router-view></router-view>
    <!-- <router-view v-slot="{Component}">
      <keep-alive :max="10">
        <component :is="Component"></component>
      </keep-alive>
    </router-view> -->
  </el-config-provider>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive } from 'vue'
import { GlobalStore } from '@/store'

// 配置element中英文
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
// import { useSentry } from './sentry'

const globalStore = GlobalStore()
// 配置element按钮文字中间是否有空格
const config = reactive({
  autoInsertSpace: false
})

// element 语言配置
const i18nLocale = computed((): any => {
  if (globalStore.language && globalStore.language === 'zh') return zhCn
  if (globalStore.language === 'en') return en
  return ''
})

// 配置全局组件大小 (small/default(medium)/large)
const assemblySize = computed((): string => globalStore.assemblySize)

// const sentry = useSentry()
onMounted(() => {
  // sentry.init()
  // sentry.setUser()
})
</script>

<style lang="scss" scoped></style>
